import { Box, styled } from "@mui/system";
import {
  autocompleteClasses,
  Grid,
  Paper,
  Popper,
  PopperProps,
  styled as styledComponent,
} from "@mui/material";
import { StyledComponent } from "@emotion/styled";

import { colors, others } from "../../styles/colors";

export const ErrorBox = styled(Box)(() => ({
  justifyContent: "center",
}));

export const GridLayout = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  height: "100vh",
}));
export const GridItem = styled(Grid)(() => ({
  minHeight: "18rem",
  minWidth: "50%",
  backgroundColor: colors.primary.light,
  borderRadius: "0.9rem",
  padding: "2rem",
  alignSelf: "center",
  boxShadow: `0.1rem 0.1rem 0.1rem 0.1rem ${colors.dropShadow[1]}`,
}));

export const ClickableEvents = {
  "& .sd-footer": {
    pointerEvents: "auto",
  },
};

export const NotePreview = (flag: boolean) => ({
  pointerEvents: flag ? "auto" : "none",
});

export const SurveyStyling: any = {
  backgroundImage: "",
  backgroundImageFit: "contain",
  backgroundImageAttachment: "scroll",
  backgroundOpacity: 1,
  isPanelless: true,
  cssVariables: {
    "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
    "--sjs-general-backcolor-dark": "rgba(248, 248, 248, 1)",
    "--sjs-general-backcolor-dim": others.otherColors[33],
    "--sjs-general-backcolor-dim-light": "rgba(249, 249, 249, 1)",
    "--sjs-general-backcolor-dim-dark": "rgba(243, 243, 243, 1)",
    "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
    "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
    "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
    "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
    "--sjs-primary-backcolor": colors.primary.main,
    "--sjs-primary-backcolor-light": "rgba(26, 59, 122, 0.1)",
    "--sjs-primary-backcolor-dark": "rgba(29, 66, 137, 1)",
    "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-base-unit": "5.6px",
    "--sjs-corner-radius": "20px",
    "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
    "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
    "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
    "--sjs-shadow-small": "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
    "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
    "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
    "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
    "--sjs-shadow-inner":
      "0px 2px 0px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 0px #000000",
    "--sjs-shadow-inner-reset":
      "0px 0px 0px 0px rgba(0, 0, 0, 0.15), 0px 0px 0px 0px #000000",
    "--sjs-border-light": "rgba(0, 0, 0, 0.09)",
    "--sjs-border-default": "rgba(17, 19, 19, 0.16)",
    "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
    "--sjs-special-red": "rgba(229, 10, 62, 1)",
    "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
    "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-green": "rgba(25, 179, 148, 1)",
    "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
    "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-blue": "rgba(67, 127, 217, 1)",
    "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
    "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
    "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
    "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
    "--sjs-article-font-xx-large-textDecoration": "none",
    "--sjs-article-font-xx-large-fontWeight": "700",
    "--sjs-article-font-xx-large-fontStyle": "normal",
    "--sjs-article-font-xx-large-fontStretch": "normal",
    "--sjs-article-font-xx-large-letterSpacing": "0",
    "--sjs-article-font-xx-large-lineHeight": "64px",
    "--sjs-article-font-xx-large-paragraphIndent": "0px",
    "--sjs-article-font-xx-large-textCase": "none",
    "--sjs-article-font-x-large-textDecoration": "none",
    "--sjs-article-font-x-large-fontWeight": "700",
    "--sjs-article-font-x-large-fontStyle": "normal",
    "--sjs-article-font-x-large-fontStretch": "normal",
    "--sjs-article-font-x-large-letterSpacing": "0",
    "--sjs-article-font-x-large-lineHeight": "56px",
    "--sjs-article-font-x-large-paragraphIndent": "0px",
    "--sjs-article-font-x-large-textCase": "none",
    "--sjs-article-font-large-textDecoration": "none",
    "--sjs-article-font-large-fontWeight": "700",
    "--sjs-article-font-large-fontStyle": "normal",
    "--sjs-article-font-large-fontStretch": "normal",
    "--sjs-article-font-large-letterSpacing": "0",
    "--sjs-article-font-large-lineHeight": "40px",
    "--sjs-article-font-large-paragraphIndent": "0px",
    "--sjs-article-font-large-textCase": "none",
    "--sjs-article-font-medium-textDecoration": "none",
    "--sjs-article-font-medium-fontWeight": "700",
    "--sjs-article-font-medium-fontStyle": "normal",
    "--sjs-article-font-medium-fontStretch": "normal",
    "--sjs-article-font-medium-letterSpacing": "0",
    "--sjs-article-font-medium-lineHeight": "32px",
    "--sjs-article-font-medium-paragraphIndent": "0px",
    "--sjs-article-font-medium-textCase": "none",
    "--sjs-article-font-default-textDecoration": "none",
    "--sjs-article-font-default-fontWeight": "400",
    "--sjs-article-font-default-fontStyle": "normal",
    "--sjs-article-font-default-fontStretch": "normal",
    "--sjs-article-font-default-letterSpacing": "0",
    "--sjs-article-font-default-lineHeight": "28px",
    "--sjs-article-font-default-paragraphIndent": "0px",
    "--sjs-article-font-default-textCase": "none",
    "--sjs-question-background": "rgba(255, 255, 255, 0)",
    "--sjs-font-size": "13.6px",
    "--sjs-questionpanel-cornerRadius": "26px",
    "--sjs-font-questiondescription-weight": "700",
    "--sjs-font-questiondescription-size": "22px",
    "--sjs-font-questiontitle-weight": "700",
    "--sjs-editorpanel-cornerRadius": "2px",
    "--sjs-editor-background": "rgba(255, 255, 255, 1)",
  },
  themeName: "default_exported",
  colorPalette: "light",
};

export const InputTextLabel = () => ({
  "& .MuiInputLabel-root": {
    display: "flex",
  },
});

export const Cursor = {
  "& .css-1bhz5qb-MuiTypography-root": {
    fontWeight: 600,
    fontSize: ".7rem",
  },
};
export const selectBox = {
  "& .MuiCheckbox-root ": {
    padding: "0rem 0.5rem",
  },
};
export const BreadcrumbPaper = styled(Paper)(() => ({
  backgroundColor: "inherit",
}));

export const BreadcrumbLeftGrid = styled(Grid)(() => ({
  padding: "1rem 1.5rem 1rem 1.5rem",
  a: {
    cursor: "pointer",
  },
}));

export const BreadcrumbRightGrid = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "end",
  padding: "1rem 1.5rem 1rem 1.5rem",
  alignItems: "end",
}));

export const StyledPopperCheckBoxAutocomplete: StyledComponent<PopperProps> =
  styledComponent(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: "border-box",
      "& ul": {
        margin: "2rem",
      },
      "& .MuiAutocomplete-option": {
        cursor: "pointer",
      },
      "&::-webkit-scrollbar": {
        width: ".25rem",
        height: "0.3rem",
        paddingLeft: "2rem",
      },
      "&::-webkit-scrollbar-track": {
        width: 0.4,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.black[6],
        borderRadius: 0.5,
        width: 0.3,
      },
    },
  });
