import colors from "../../../../../../../styles/colors/colors";

export const GridContainer = () => ({
  backgroundColor: colors.backgroundColor[2],
  borderBottom: `1px solid ${colors.borders[1]}`,
  paddingLeft: "1rem"
});

export const ICD10TableHead = () => ({
  "& .MuiTableCell-root": {
    padding: "1.5rem 0rem 1.9rem 1.5rem ",
    borderBottom: `1.8px solid ${colors.dropShadow[4]}`,
  },
});

export const Icd10CodeTableBody = () => ({
  "& .MuiTableCell-root": {
    padding: "1.5rem 0.5rem 2.1rem 1.5rem",
  },
});

export const HorizontalDivider = () => ({
  margin: "1.2rem 0 0.8rem 0",
  width: "100%",
});

export const HeaderTitle = () => ({
  paddingTop: "0.3rem",
  paddingLeft: "1rem",
});

export const FieldLabel = () => ({
  padding: "0.8rem 0",
});

export const Address = () => ({
  display: "inline-block",
  wordWrap: "break-word",
  hyphens: "manual",
  width: "8.9rem",
});

export const ItemIconBox = () => ({
  color: colors.primary.main,
  paddingRight: "0.4rem",
});

export const GridLeftBody = () => ({
  padding: "1.8rem 0.5rem 3rem 2rem",
});

export const GridRightBody = () => ({
  padding: "1.8rem 0.5rem 3rem 1rem",
});

export const fieldBox = (address: boolean | undefined) => ({
  height: address ? "auto" : "3.2rem",
  minHeight: address ? "5rem" : "3.2rem",
});

export const PhysicianDetailsGrid = () => ({
  paddingLeft: "3rem ",
  display: "flex",
});

export const VerticalDivider = () => ({
  paddingBottom: "0.5rem ",
});

export const PhysicianDetailsContainer = () => ({
  paddingTop: "2rem",
});

export const PhysicianDetailsBox = () => ({
  display: "flex",
  flexDirection: "column"
})

export const physicianDetailsStyle = () => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
})

export const AddressGrow = () => ({
  flexGrow: 1,
  paddingTop: "0rem",
})

export const fieldGrid = (isLast: boolean | undefined) => ({
  paddingTop: isLast ? "0rem" : "1rem"
});