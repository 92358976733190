import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { ApplicationState } from "../../../../../store/ApplicationState";
import { AuthAndEligibilityHistoryPayload } from "../../../../../models/AuthorizationHistory";
import { EligibiltyHistoryDispatchTypes } from "../../../../../models/DispatchTypes/AuthorizationHistory";
import { getEligibilityHistory } from "../../../../../actions/AuthorizationHistory";
import EligibilityHistoryData from "../components/EligibilityHistory";

const mapStateToProps = ({
  eligibilityHistoryState,
  updatePatientHeaderState,
}: ApplicationState) => ({
  eligibilityHistoryResp: eligibilityHistoryState,
  updatePatientHeaderData: updatePatientHeaderState,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<
    ApplicationState,
    Record<string, never>,
    Action<EligibiltyHistoryDispatchTypes>
  >
) => {
  return {
    getEligibilityHistoryRecord: (
      payload: AuthAndEligibilityHistoryPayload
    ) => {
      dispatch(getEligibilityHistory(payload));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EligibilityHistoryData);
